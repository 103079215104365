import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { IContentTypeItemType } from "../../../../../../../interfaces";
import {
  getFormData,
  initForm
} from "../../../../../../../shared/utility";

interface IProps extends IContentTypeItemType {}

const ContentTypeProducts: React.ForwardRefExoticComponent<
  IProps
> = forwardRef(({ blockContent, setIsValid }, ref) => {
  const [controlsState, setControlsState] = useState({
    controls: {
			products:{value:[
				{
					item:"",
					description:"",
					quantity:1,
					unit:"pcs",
					unitPrice:0,
					discount:0,
					vat:25.5,
				},
			]},
      currency:{value:"€"},
      showTotal:{value:true},
      color:{value:"#dddddd"},

			labels:{value:{
					item:"Item",
					description:"Description",
					quantity:"Quantity",
					unit:"Unit",
					unitPrice:"Price",
					discount:"Discount",
					vat:"VAT",
					total:"Total",
			}},


			/*
			leftProducts:{value:[
				{value:"",fontSize:1},
				{value:"",fontSize:2},
				{value:"",fontSize:4},
			]},
			rightProducts:{value:[
				{value:"",fontSize:1},
				{value:"",fontSize:2},
				{value:"",fontSize:4},
			]},
      jsonData: {
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        value: defaultValues, //JSON.stringify(defaultValues),
      },
			*/
    },
    formIsValid: true
  });

  useEffect(() => {
    if (blockContent) {
      const newControlsState = initForm(controlsState.controls, blockContent);
      setControlsState(newControlsState);
    }
    // eslint-disable-next-line
  }, [blockContent]);

  useEffect(() => {
    setIsValid(controlsState.formIsValid);
  }, [controlsState.formIsValid, setIsValid]);

  useImperativeHandle(ref, () => ({
    getData: () => {
      return getFormData(controlsState.controls);
    }
  }));

  return null
});

export default ContentTypeProducts;

