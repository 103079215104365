import React, { useEffect } from 'react';
import { connect } from 'react-redux';
//import { RouteComponentProps } from 'react-router';
import { RouteComponentProps } from "../../withRouter";
import { useParams, useLocation } from 'react-router-dom';

import ListContainer from '../../components/ListContainer/ListContainer';
import ListHeader from '../../components/ListHeader/ListHeader';
import ListSpinner from '../../components/UI/ListSpinner/ListSpinner';
import UsersList from '../../components/Users/UsersList';
import { ESearchType } from '../../enums';
import EUserRole from '../../enums/user-roles';
import { IAppState, IUser, IUserGroup } from '../../interfaces';
import { hasAccess } from '../../shared/utility';
import * as actions from '../../store/actions';

import UserGroupsList from '../../components/Users/UserGroupsList';

interface IStateProps {
  loading: boolean;
  error: string;
  users: Array<IUser>;
  currentUser: IUser | null;

  userGroups: Array<IUserGroup>;
  ugloading: boolean;
  ugerror: string;

}

interface IDispatchProps {
  onFindUsers: (organizationId: string) => void;
  onSearchUsers: (queryString: string) => void;

  onFindUserGroups: (organizationId: string) => void;
}

interface IProps extends RouteComponentProps, IStateProps, IDispatchProps {
  organizationId: string;
}

const Users: React.FC<IProps> = props => {
  const {
    organizationId,
    onFindUsers,
    users,
    loading,
    history,
    match,
    currentUser,
    onSearchUsers,

    onFindUserGroups,
    userGroups,
    ugloading,
  } = props;

  const location = useLocation();

  useEffect(() => {
    onFindUsers(organizationId);
  }, [onFindUsers, organizationId]);

  useEffect(() => {
    onFindUserGroups(organizationId);
  }, [onFindUserGroups, organizationId]);

  const addUser = () => {
    if (hasAccess(currentUser, EUserRole.SUPERADMIN)) {
      //history.push(`${match.url}/users/add`);
      history.push(`${location.pathname}/users/add`);
    } else {
      history.push("/users/add");
    }
  };

  const addUserGroup = () => {
    if (hasAccess(currentUser, EUserRole.SUPERADMIN)) {
      //history.push(`${match.url}/usergroups/add`);
      history.push(`${location.pathname}/usergroups/add`);
    } else {
      history.push("/usergroups/add");
    }
  };


  const searchClickHandler = (id: string) => {
    if (hasAccess(currentUser, EUserRole.SUPERADMIN)) {
      //history.push(`${match.url}/users/${id}`);
      history.push(`${location.pathname}/users/${id}`);
    } else {
      //history.push(`${match.url}/${id}`);
      history.push(`${location.pathname}/${id}`);
    }
  };

  const searchChangedHandler = (queryString: string) => {
    onSearchUsers(queryString);
  };

  return (
    <ListContainer>
      <ListHeader
        title="Users"
        onCreate={{
          text: "Add new user",
          clicked: () => {
            addUser();
          }
        }}
        onSearch={{
          type: ESearchType.USERS,
          searchFunc: searchChangedHandler,
          clicked: searchClickHandler
        }}
      />

      {users?users.filter((x:IUser)=>x.allowLogin && !x.isDeleted).length:0} active users<br/>
      {users?users.filter((x:IUser)=>x.allowLogin && !x.isDeleted && x.role === EUserRole.ENTREPRENEUR).length:0} Managers<br/>
      {users?users.filter((x:IUser)=>x.allowLogin && !x.isDeleted && x.role === EUserRole.ADMIN).length:0} Admins<br/>
      {users?users.filter((x:IUser)=>x.allowLogin && !x.isDeleted && x.role === EUserRole.SALES_PERSON).length:0} Sales Persons<br/>
      {users?users.filter((x:IUser)=>!x.allowLogin && !x.isDeleted).length:0} Inactive<br/>
      {users?users.filter((x:IUser)=>x.isDeleted).length:0} Deleted<br/>
      

      {loading ? <ListSpinner /> : <UsersList {...props} users={users} />}

      
      <ListHeader
        title="User Groups"
        onCreate={{
          text: "Add new group",
          clicked: () => {
            addUserGroup();
          }
        }}
      />

      {ugloading ? <ListSpinner /> : <UserGroupsList {...props} userGroups={userGroups} />}

    </ListContainer>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser,
    loading: state.users.loading,
    error: state.users.error,
    users: state.users.users,

    userGroups: state.userGroups.userGroups,
    ugloading: state.userGroups.loading,
    ugerror: state.userGroups.error,

  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFindUsers: organizationId => dispatch(actions.findUsers(organizationId)),
    onSearchUsers: queryString => dispatch(actions.searchUsers(queryString)),

    onFindUserGroups: organizationId => dispatch(actions.findUserGroups(organizationId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
